<template>
	<div class=" justify-content-center  mt-1 mt-md-0 row w-full pb-5">
		<b-col col md="8" xl="5">
			<user-profile />
			<user-profile-password class="mt-4" />
		</b-col>
	</div>
</template>

<script>
import { BCol } from 'bootstrap-vue';
import UserProfile from './UserProfile.vue';
import UserProfilePassword from './UserProfilePassword.vue';

export default {
	name: 'Profile',

	components: {
		BCol,
		UserProfile,
		UserProfilePassword,
	},
};
</script>

<style lang="scss" scoped>
.nav-pills .router-link-active {
	border-color: #8bc53f;
	background-color: #8bc53f;
	box-shadow: 0 4px 18px -4px rgb(139 197 63 / 65%);
	color: #fff;
}
</style>
