<template>
	<div class="bg-white p-3 rounded">
		<div>
			<h2 class="mb-1">Change Password</h2>
			<hr />
		</div>

		<validation-observer ref="formRules">
			<b-form @submit.prevent="handleSubmit">
				<!-- old password -->
				<b-form-group class="w-full" label="Old password" label-for="old_password">
					<validation-provider #default="{ errors }" mode="lazy" name="old_password" rules="required">
						<b-form-input
							id="old_password"
							v-model="formData.old_password"
							type="password"
							autocomplete="password"
							:state="errors.length > 0 ? false : null"
							placeholder="old password"
						/>

						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<!-- new password -->
				<b-form-group class="w-full" label="New password" label-for="new_password">
					<validation-provider #default="{ errors }" mode="lazy" name="new_password" rules="required">
						<b-form-input
							id="new_password"
							v-model="formData.new_password"
							type="password"
							autocomplete="new-password"
							:state="errors.length > 0 ? false : null"
							placeholder="new password"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<b-form-group class="w-full">
					<!-- <b-button variant="outline-danger" class="mr-1" type="reset"> cancel </b-button> -->
					<b-button variant="primary" type="submit"> Update Password </b-button>
				</b-form-group>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { BFormInput, BFormGroup, BForm, BButton } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	name: 'UserProfilePassword',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,

		BButton,
	},

	data() {
		return {
			required,
			formData: { old_password: '', new_password: '' },
		};
	},

	computed: {
		...mapState('auth', ['user']),
	},

	methods: {
		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			await store.dispatch('auth/changePassword', { ...this.formData });
		},
	},
};
</script>
