<template>
	<div class="bg-white p-3 rounded">
		<div>
			<h2 class="mb-1">Your information</h2>
			<hr />
		</div>

		<validation-observer ref="formRules">
			<b-form @submit.prevent="handleSubmit">
				<div class="mb d-flex justify-content-center">
					<b-avatar size="80" :src="user.avatar" alt="user profile image">
						<template #badge>
							<edit-2-icon size="1x" class="text-white cursor-pointer" @click="$bvModal.show('edit-profile-image-modal')" />
						</template>
					</b-avatar>

					<b-modal
						id="edit-profile-image-modal"
						ref="newFormModal"
						title="Update image"
						ok-title="Save"
						centered
						cancel-variant="outline-secondary"
					>
						<b-form-file id="file-small" v-model="formData.avatar" size="sm"></b-form-file>
					</b-modal>
				</div>

				<!-- name -->
				<b-form-group class="w-full" label="Name" label-for="name">
					<validation-provider #default="{ errors }" mode="lazy" name="name" rules="required">
						<b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" placeholder="Name" />
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<!-- email -->
				<b-form-group class="w-full" label="Email" label-for="email">
					<validation-provider #default="{ errors }" mode="lazy" name="email" rules="required|email">
						<b-form-input
							id="email"
							v-model="formData.email"
							type="email"
							:state="errors.length > 0 ? false : null"
							placeholder="email"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<!-- phone -->
				<b-form-group class="w-full" label="Phone" label-for="phone">
					<validation-provider #default="{ errors }" mode="lazy" name="phone" rules="required">
						<b-form-input
							id="phone"
							v-model="formData.phone"
							type="number"
							maxlength="9"
							:state="errors.length > 0 ? false : null"
							placeholder="phone"
						/>
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<b-form-group class="w-full">
					<b-button variant="primary" type="submit"> Update </b-button>
				</b-form-group>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Edit2Icon } from 'vue-feather-icons';
import { BFormInput, BFormGroup, BForm, BAvatar, BButton, BFormFile } from 'bootstrap-vue';
import { required, email } from '@validations';
import { getState, parseFileToBase64, saveState } from '@/utils/helpers';
import api from '@/utils/api';

export default {
	name: 'UserProfile',
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BFormFile,
		BButton,
		BAvatar,
		Edit2Icon,
	},

	data() {
		return {
			required,
			email,

			formData: { name: '', phone: '', email: '', avatar: [] },
		};
	},

	computed: {
		...mapState('auth', ['user']),
	},

	created() {
		this.formData.name = this.user.name;
		this.formData.email = this.user.email;
		this.formData.phone = this.user.phone;
	},

	methods: {
		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			const userData = {
				name: this.formData.name,
				phone: this.formData.phone,
				email: this.formData.email,
			};

			if (this.formData.avatar && this.formData.avatar.name) {
				userData.avatar = await parseFileToBase64(this.formData.avatar);
			}

			const { data, status } = await api.call({ path: 'users/profile', method: 'POST', data: userData });

			if (status === 200) {
				this.updateLocalUserData(data.data);
			}
		},

		updateLocalUserData(data) {
			saveState('user', {
				...getState('user'),
				avatar: data.avatar,
				name: data.name,
				email: data.email,
				phone: data.phone,
			});

			this.user.avatar = data.avatar;
			this.user.name = data.name;
			this.user.phone = data.phone;
			this.user.email = data.email;
		},
	},
};
</script>
